import { ItemTypes } from '~/types';

import { hideArtistApi } from '~/app/lib/songwhipApi/artists';

import { deleteItemSuccess } from '../../lib/deleteItemSuccess';
import { Dispatch } from '../../types';

const hideArtistAction =
  ({ artistId }: { artistId: number }) =>
  async (dispatch: Dispatch) => {
    const artist = await hideArtistApi({ artistId });

    dispatch(
      deleteItemSuccess({
        id: artist.id,
        type: ItemTypes.ARTIST,
        path: artist.path,
      })
    );
  };

export default hideArtistAction;
